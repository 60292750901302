import React, { useEffect } from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getOgImage, priceToFloat } from "../../utils/functions"
import CategoryContent from "../../components/category/CategoryContent"
import { WPViewContentProducts } from "../../utils/WPpixel"

const ProductCategories = props => {
  const {
    pageContext: {
      name,
      seo,
      uri,
      id,
      children,
      products,
      description,
      acfProductCategory,
      options,
    },
  } = props
  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated

  useEffect(() => {
    let wpProducts = [];
    let gtmProducts = [];
    if (products.nodes) {
      products.nodes.forEach((product, index) => {
        const price = product.salePrice
          ? product.salePrice
          : product.regularPrice
        if (price) {
          let productFloatPrice = product.salePrice
            ? priceToFloat(product.salePrice)
            : priceToFloat(product.regularPrice)

          let isEbook = false
          if (product.productTags.nodes) {
            product.productTags.nodes.forEach(element => {
              if (element.name === "ebook") {
                isEbook = true
              }
            })
          }
          if (!isEbook) {
            wpProducts.push({
              id: product.databaseId,
              name: product.name,
              price: productFloatPrice,
              category: "Żywność, napoje i tytoń > Żywność",
            })
            gtmProducts.push({
              id: product.databaseId,
              item_id:product.databaseId,
              item_name: product.name,
              item_brand: "",
              price: productFloatPrice,
              index: index,
              item_category: "Żywność, napoje i tytoń > Żywność",
              item_list_name: name,
            })
          }
        }
      })
    }

    if (typeof window !== 'undefined'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 
        event: "view_item_list",
        ecommerce:{
          currencyCode: 'PLN',
          currency: "PLN",
          items: gtmProducts
        }
       });
    }


    WPViewContentProducts("ProductList", wpProducts)
  }, [])

  // const options = {
  //   headingPrice: '',
  //   buttonPrice: '',
  //   textPrice: '',
  //   headingSort: '',
  //   headingCategories: '',
  //   typeSort: [
  //     {
  //       heading: '',
  //       type: ''
  //     }
  //   ],
  // }
  const lang = locale.substring(0, 2)
  return (
    <Layout lang={lang} page="productCategories" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={name}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"productCategories"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          <CategoryContent
            id={id}
            lang={lang}
            options={options.template.productCategory}
            productBoxButton={acfProductCategory.buttonTextProduct}
            breadcrumbs={acfProductCategory.breadcrumbs}
            children={children}
            products={products}
            title={name}
            translated={translated}
            description={description}
          />
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default ProductCategories
